
    /**
    * 主播信息
    * 
    **/
    
    // @ts-nocheck
    import request from '../utils/http'
    type Serve<T, G> = (data?: T) => Promise<G>
    export class IReqApiLiveRealtimeGetLiveInfo18812 {
  /**
   * 主播编号
   */
  userNo: string | number;
  /**
   * 开播时间
   */
  startTime: string | number;
}
    export class IResApiLiveRealtimeGetLiveInfo18812 {
  code?: number;
  data?: {
    /**
     * 在线观看数
     */
    audiencesOnline?: number;
    /**
     * 粉丝数
     */
    fansNum?: number;
    /**
     * 总销量
     */
    goodsNum?: number;
    /**
     * 昵称
     */
    nickName?: string;
    /**
     * 总销售额（实际gmv）
     */
    orderAmount?: number;
    /**
     * 开播时间
     */
    startTime?: string;
    /**
     * 已直播时间
     */
    livedTime?: string;
    /**
     * 头像封面
     */
    liveImg?: string;
    /**
     * 客单价
     */
    perOrderPriceAvg: number;
    liveStatus: number;
    likeTrendSum: number;
    'headImg ': string;
  };
  msg?: string;
  success?: boolean;
}
    const http: Serve<IReqApiLiveRealtimeGetLiveInfo18812, IResApiLiveRealtimeGetLiveInfo18812> = (data?) =>  request({
      method: 'GET',
      url: '/api/live/realtime/getLiveInfo',
      params: data
    }) 
    export default http