
    /**
    * 商品信息
    * 
    **/
    
    // @ts-nocheck
    import request from '../utils/http'
    type Serve<T, G> = (data?: T) => Promise<G>
    export class IReqApiLiveRealtimeGetGoodsDetail18813 {
  userNo: string;
  orderBy: {
    /**
     * goodCreatTime   上架时间     goodsPrice 价格
     */
    column: string;
    sort: string;
  }[];
  /**
   * 开播时间
   */
  startTime: string;
}
    export class IResApiLiveRealtimeGetGoodsDetail18813 {
  code?: number;
  data?: {
    currentPage?: number;
    pageSize?: number;
    pages?: number;
    records?: {
      /**
       * 名称
       */
      goodsName: string;
      /**
       * 销量
       */
      goodsNum: number;
      /**
       * 价格
       */
      goodsPrice: number;
      /**
       * gmv
       */
      orderAmount: number;
      /**
       * 上架时间
       */
      createTime: string;
      /**
       * 图片地址
       */
      goodsImg: string;
    }[];
    startIndex?: null;
    /**
     * 商品数
     */
    total?: number;
  };
  msg?: string;
  success?: boolean;
}
    const http: Serve<IReqApiLiveRealtimeGetGoodsDetail18813, IResApiLiveRealtimeGetGoodsDetail18813> = (data?) =>  request({
      method: 'POST',
      url: '/api/live/realtime/getGoodsDetail',
      data:data
    }) 
    export default http