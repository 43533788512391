/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useEffect, useState } from 'react'

interface IPrpos {
  time: number
  tips?: string
  cbFunc?: any
  clearTimer?: boolean
  endText?: string
}

export const countdownTime = (props: IPrpos) => {
  const { time, cbFunc, clearTimer } = props
  const [countdownTime, setCountdownTime] = useState<number>(time)
  let tips = props.tips ? props.tips : ''
  let endText = props.endText ? props.endText : '倒计时停止'
  let timer: any = null
  /*
   * 倒计时自定义hook
   * callback  回调方法
   * frequency 间隔时间
   */
  const useSetInterval = (callback: any, frequency: number) => {
    const ref: React.MutableRefObject<any> = useRef()

    useEffect(() => {
      ref.current = callback
    })

    useEffect(() => {
      timer = setInterval(() => {
        ref.current()
      }, frequency)
      return () => clearInterval(timer)
    })
  }

  if (!clearTimer) {
    useSetInterval(() => {
      if (countdownTime === 0) {
        setCountdownTime(time)
        cbFunc()
      } else {
        setCountdownTime(countdownTime - 1)
      }
    }, 1000)
  } else {
    clearInterval(timer)
    console.log('停止计时器')
    tips = endText
  }
  return <>{tips.replace('{time}', String(countdownTime))}</>
}
