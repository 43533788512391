import React from 'react'
import './style/index.less'

export default function() {
  return (
    <div className="demoPage">
      <div className="container">
        <h3>DEMO</h3>
      </div>
    </div>
  )
}
