import React from 'react';
import ReactDOM from 'react-dom';
import Toast from './Toast';

export default (msg:string) =>{
  const div = document.createElement('div');
  document.body.appendChild(div);
  ReactDOM.render(<Toast msg={msg}/>, div);
  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(div)
    document.body.removeChild(div)
  }, 2000)
}