
/**
 * 获取地址栏参数
 * @export
 * @param {*} name
 * @returns
 */
export function GetQueryString(name) {
  const url = window.location.href
  const reg = new RegExp(`(\\?|&)${name}=([^&]*)(&|$)`, 'i')
  const r = url.match(reg)

  if (r !== null) {
    return unescape(r[2])
  }
  return null
}

/**
 * transformShortNum
 * 转换为带单位的短数据
 * @param num 需要转换的数字（非数字 或是 - 将直接返回 -）
 * @param decimal 返回的小数位
 * @param addUnit 返回值是否添加单位:(万或亿)
 * @param retInt 当数据小于1000万时，是否返回无小数位的整数
 * @param units[] 自定义单位
 **/
export const transformShortNum = (
  num: any,
  decimal = 0,
  addUnit = true,
  retInt = false,
  // customizeUnit: false,
  units = ['万', '亿'],
  afterUnit = ''
) => {
  if (num === 0 || Number(num) === 0) {
    return 0 + afterUnit
  }
  if (num === '-' || isNaN(num) || !Number(num)) {
    return '-'
  }
  let isNegativeNumber = false
  let ret = ''
  if (num < 0) {
    isNegativeNumber = true
    // eslint-disable-next-line no-param-reassign
    num = Math.abs(num)
  }

  if (num >= 100000000) {
    ret = (num / 100000000).toFixed(decimal) + (addUnit ? units[1] : '')
  } else if (num >= 10000) {
    ret = (num / 10000).toFixed(decimal) + (addUnit ? units[0] : '')
  } else {
    ret = (retInt ? num.toFixed(0) : (decimal ? num.toFixed(decimal) : num))
  }
  return isNegativeNumber ? `-${ret}${afterUnit}` : `${ret}${afterUnit}`
}

/**
 * 格式化数字为金额格式
 * @param  {string} s 原数字串
 * @param  {int} n 小数位
 * @return {string} 格式化后的字符串
 */
export const FormatNumbertoRMB = (s: string | number, n: number) => {
  if (s === '-') { return s }
  if (s === null) { return '-' }
  n = n >= 0 && n <= 20 ? n : 0;
  // eslint-disable-next-line no-useless-escape
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1],
    t = ""

  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
  }
  let ret = ''
  if (n) {
    ret = t.split("").reverse().join("") + "." + r
  } else {
    ret = t.split("").reverse().join("")
  }
  return ret
}


