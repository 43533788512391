import axios from 'axios';
import Toast from '../components/toast';
import envConfig from '../envConfig'

const env: string = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'
// @ts-ignore
const baseUrl = envConfig[env].api;
const loginHost = envConfig[env].loginHost;

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  // headers: { 'Content-Type': 'multipart/form-data' },
  withCredentials: false, // 允许携带cookie
  validateStatus(status) {
    return status < 500
  }
});

// 添加一个请求拦截器
instance.interceptors.request.use(
  request => {
    const token = localStorage.getItem('token') || '';
    if (process && process.env && process.env.REACT_APP_ENV !== 'mock') {
      request.headers.common['x-token'] = token
    }
    return request
  },
  err => Promise.reject(err)
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const { success, code } = response.data;
    if (success) {
      return Promise.resolve(response.data)
    } else if (code === 1000) {
      Toast(response.data.message)
      setTimeout(() => {
        window.location.replace(`${loginHost}/login`)
      }, 3 * 1000)
    } else {
      Toast(response.data.message)
    }
    return Promise.reject()
  },
  err => {
    Toast('服务器报错')
    return Promise.reject(err)
  }
);

export default instance
