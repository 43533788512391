
    /**
    * 618 战报 主播维度
    * 
    **/
    
    // @ts-nocheck
    import request from '../utils/http'
    type Serve<T, G> = (data?: T) => Promise<G>
    export class IReqApiGrandZhubo29505 {
  /**
   * 祖名
   */
  groupName: string | number;
}
    /**
 * YResult<List<ZhuboGrandVo>> :YResult
 */
export class IResApiGrandZhubo29505 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * ZhuboGrandVo
   */
  data?: {
    id?: {};
    /**
     * 组名
     */
    groupName?: {};
    /**
     * 领导
     */
    leader?: {};
    /**
     * 部门
     */
    department?: {};
    /**
     * 主播名字
     */
    userName?: {};
    /**
     * 负责人
     */
    charger?: {};
    /**
     * 目标gmv
     */
    targetGmv?: {};
    /**
     * 支付gmv占比
     */
    payGmvRate?: {};
    /**
     * 时间进度
     */
    timeProcess?: {};
    /**
     * 直播时长
     */
    liveDuration?: {};
    /**
     * 支付商品数量
     */
    payGoodsCount?: {};
    /**
     * 支付gmv
     */
    payGmv?: {};
    /**
     * 成交商品数量
     */
    dealGoodsCount?: {};
    /**
     * 成交gmv
     */
    dealGmv?: {};
  }[];
  exceptionMsg?: {};
}
    const http: Serve<IReqApiGrandZhubo29505, IResApiGrandZhubo29505> = (data?) =>  request({
      method: 'GET',
      url: '/api/grand/zhubo',
      params: data
    }) 
    export default http