import React, { useEffect, useState } from 'react'
import { Button, Spin } from 'antd'

import { transformShortNum } from '../../../utils/index'
import EmptyStatus from '../../../components/EmptyStatus'
import { countdownTime as CountdownTime } from '../../../components/LiveDataCountdownTime/index'
import API from '../../../api/index'

import kshd from '../../../assets/kshd.png'
import dyhd from '../../../assets/dyhd.png'
import logo from '../../../assets/hddefault.png'
import liveingIcon from '../../../assets/liveing.png'

import './style/index.less'

export default () => {
  const [liveList, setLiveList] = useState<any>([])
  const [isSpinning, setIsSpinning] = useState<boolean>(true)

  /* Get Data */
  const getListData = async () => {
    const res = await API.ApiLiveRealtimeGetLiveList18811()
    if (res && res.data) {
      setLiveList(res.data)
      setIsSpinning(false)
    }
  }

  /* Actions */
  // goto detail page
  const openDashBoard = (liveInfo: any) => {
    window.location.href = `${window.location.origin}/datav?liveId=${liveInfo.userNo}&startTime=${liveInfo.startTime}`
  }

  /* Loaded */
  useEffect(() => {
    getListData()
  }, [])

  return (
    <div className="dataListPage">
      <div className="container">
        <div className="page_title">
          <h1 className="title fl">单场实时数据</h1>
          <span className="refTime" style={{ float: 'right', width: '3rem' }}>
            {!isSpinning && (
              <CountdownTime
                tips="{time}秒后更新数据"
                time={20}
                cbFunc={() => getListData()}
              />
            )}
          </span>
        </div>
        <div className="note-bar">
          <div className="left fl mgr10">
            <i className="iconfont icon-tips"></i>
          </div>
          <div className="right">
            直播间隔低于半小时的场次会自动合并成一场直播，单场直播数据会低于整天销售数据。
          </div>
        </div>
        <Spin spinning={isSpinning} size="large" />
        <div className="liveListBox">
          {liveList && liveList.length
            ? liveList.map((item: any, index: number) => {
                return (
                  <div
                    className="cardItem"
                    key={`card_${index}`}
                    onClick={() => openDashBoard(item)}
                  >
                    <div className="brand">
                      <img
                        src={item.liveType === '快手' ? kshd : dyhd}
                        alt={item.nickName}
                        className="brandImg"
                      />
                      <div className="liveStatus">
                        <img src={liveingIcon} className="liveingIcon" />
                        直播中
                      </div>
                    </div>
                    <div className="coverbox">
                      <img
                        src={
                          `${item.liveImg}?x-oss-process=image/resize,w_300,limit_0/quality,q_80` ||
                          logo
                        }
                        alt={item.nickName}
                      />
                    </div>
                    <div className="liveDetailBox">
                      <div className="liveName">{item.nickName}</div>
                      <div className="liveInfo">开播时间：{item.startTime}</div>
                      <div className="liveInfo">
                        在线人数：
                        {!isNaN(item.audiencesOnline)
                          ? transformShortNum(
                              item.audiencesOnline,
                              2,
                              true,
                              true
                            )
                          : 0}
                      </div>
                      <div className="liveInfo">
                        成交商品量：
                        {item.goodsNum
                          ? transformShortNum(item.goodsNum, 2, true, true)
                          : 0}
                      </div>
                      <div className="liveInfo">
                        成交额(退款后)：￥
                        {item.actGmv
                          ? transformShortNum(item.actGmv, 2, true, false, [
                              'W',
                              'E',
                            ])
                          : 0}
                      </div>
                      <Button type="primary" className="btn">
                        实时数据
                      </Button>
                    </div>
                  </div>
                )
              })
            : !isSpinning && (
                <EmptyStatus
                  type={1}
                  text="暂无直播中的主播"
                  style={{
                    margin: '2.6rem auto 0',
                    textAlign: 'center',
                    lineHeight: '1.6',
                  }}
                />
              )}
        </div>
      </div>
    </div>
  )
}
