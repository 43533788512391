import React, { useEffect } from 'react'
import { Spin } from 'antd'
import {
  GetQueryString,
  transformShortNum,
} from '../../../utils/index'
import './style/index.less'
import API from '../../../api/index'

const teamIcon = {
  'T0001': { url: 'https://cdn.ywwl.com/bps/dianhu-m/2021618/cbtn-T0001.png', name: '红队'},
  'T0002': { url: 'https://cdn.ywwl.com/bps/dianhu-m/2021618/cbtn-T0002.png', name: '蓝队'},
  'T0003': { url: 'https://cdn.ywwl.com/bps/dianhu-m/2021618/cbtn-T0003.png', name: '紫队'},
}
const paramsName = [
  {name: 'leader', title: '队长'},
  {name: 'department', title: '部门'},
  {name: 'charger', title: '负责人'},
  {name: 'targetGmv', title: '目标付款GMV', render: (value) => transformShortNum(value, 2)},
  {name: 'payGmvRate', title: '付款GMV完成率', isHighlight: true, render: (value) => transformShortNum(value || value === 0 ? value * 100 : '-', 2, true, false, ['万', '亿'], '%') },
  {name: 'timeProcess', title: '时间进度', render: (value) => transformShortNum(value || value === 0 ? value * 100 : '-', 2, true, false, ['万', '亿'], '%')},
  {name: 'liveDuration', title: '直播时长', render: (value) => value},
  {name: 'payGoodsCount', title: '付款商品量', render: (value) => transformShortNum(value, 2, true, true)},
  {name: 'payGmv', title: '付款GMV', render: (value) => transformShortNum(value, 2)},
  {name: 'dealGoodsCount', title: '成交商品量', render: (value) => transformShortNum(value, 2, true, true)},
  {name: 'dealGmv', title: '成交GMV', render: (value) => transformShortNum(value, 2)},
]

export default () => {
  const tid = GetQueryString('tid');
  const [loading, setLoadign] = React.useState(true)
  const [groupItem, setGroupItem] = React.useState([])
  const getGroupInfo = async () => {
    const res = await API.ApiGrandZhubo29505({ groupName: teamIcon[tid].name });
    setLoadign(false);
    setGroupItem(res?.data || []);
  }
  useEffect(() => {
    getGroupInfo();
  }, [])

  return (
    <div className="activity618TeamPage">
      <div className="container">
        <Spin spinning={loading}>
          <div className="left-area">
            <div className="white">
              <img src={teamIcon[tid].url} alt="" />
              {teamIcon[tid].name}
            </div>
            <div className="group-list activity618TeamPage-left-area">
              {paramsName.map((item) => (
                <div key={item.name} className={["block", item.isHighlight ? 'highlight' : ''].join(' ')}>
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="right-area">
            <div className="inner">
              <div className="top-area">
                {groupItem.map((item) => (
                  <div key={item.userName} className="block">{item.userName}</div>
                ))}
              </div>
              <div
                className="bottom-area"
                onScroll={(e: any) => {
                  const headElem = document.getElementsByClassName("activity618TeamPage-left-area")[0];
                  headElem.scrollTop= e.target.scrollTop;
                }}
              >
                {groupItem.map((item) => (
                  <div key={item.userName} className="group-list">
                    {paramsName.map((paramItem) => (
                      <div key={paramItem.name} className={["block", paramItem.isHighlight ? 'highlight' : ''].join(' ')}>
                        {paramItem.render ? paramItem.render(item[paramItem.name]) : item[paramItem.name]}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}
