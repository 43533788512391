
import ApiLiveRealtimeGetLiveInfo18812 from './ApiLiveRealtimeGetLiveInfo18812'
    import ApiLiveRealtimeGetGoodsDetail18813 from './ApiLiveRealtimeGetGoodsDetail18813'
    import ApiLiveRealtimeGetLiveList18811 from './ApiLiveRealtimeGetLiveList18811'
    import ApiGrandZhubo29505 from './ApiGrandZhubo29505'
    import ApiGrandCompany29519 from './ApiGrandCompany29519'
    import ApiGrandTeam29512 from './ApiGrandTeam29512'


export default {
  ApiLiveRealtimeGetLiveInfo18812,
  ApiLiveRealtimeGetGoodsDetail18813,
  ApiLiveRealtimeGetLiveList18811,
  ApiGrandZhubo29505,
  ApiGrandCompany29519,
  ApiGrandTeam29512
}
    
    