import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import moment from 'moment'
import {
  AlignLeftOutlined,
  UserOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons'

import {
  GetQueryString,
  transformShortNum,
  objectSort,
} from '../../../utils/index'
import './style/index.less'
import EmptyStatus from '../../../components/EmptyStatus'
import { useCountUp } from 'react-countup'
import { countdownTime as CountdownTime } from '../../../components/LiveDataCountdownTime/index'
import API from '../../../api/index'

interface IAuchorInfo {
  perOrderPriceAvg: number | string
  audiencesOnline: number | string
  fansNum: number | string
  goodsNum: number | string
  goodsIdNum: number | string
  nickName: string
  orderAmount: number
  startTime: string
  livedTime: string
  liveImg: string
  liveStatus: number
  likeTrendSum: number | string
  totalFansIncrease: number | string
  headImg: string
  orderNum: number | string
  maxAudiencesOnline: number | string
}
const userNo: string = GetQueryString('liveId')
const startTime: string = GetQueryString('startTime')
const page = 1
const pageSize: number = 1000
const units: string[] = ['W', 'E']
// 列表排序
let goodsListSortParams = {
  column: 'createTime',
  sort: 'DESC',
}

export default () => {
  const [sortOnline, setSortOnline] = useState<number>(2) // 0 未选择 1 向上 2 向下
  const [sortSaleNum, setSortSaleNum] = useState<number>(0) // 0 未选择 1 向上 2 向下
  const [sortGoodsPrice, setSortGoodsPrice] = useState<number>(0) // 0 未选择 1 向上 2 向下
  const [sortGoodsGmv, setSortGoodsGmv] = useState<number>(0) // 0 未选择 1 向上 2 向下
  const [isLiveing, setIsLiveing] = useState<boolean>(false) // 是否直播中
  const [auchorInfo, setAuchorInfo] = useState<IAuchorInfo>({
    perOrderPriceAvg: '-',
    audiencesOnline: '-',
    fansNum: '-',
    goodsNum: '-',
    goodsIdNum: '-',
    nickName: '-',
    orderAmount: 0,
    startTime: '-',
    livedTime: '-',
    liveImg: '',
    liveStatus: 0,
    likeTrendSum: '-',
    // orderAmount: 0,
    totalFansIncrease: '-',
    headImg: '',
    orderNum: '-',
    maxAudiencesOnline: '-',
  })
  const sortParams: any = {
    userNo: userNo,
    currentPage: page,
    pageSize: pageSize,
    startTime: startTime,
    orderBy: [
      {
        column: 'createTime',
        sort: 'DESC',
      },
    ],
  }

  const [goodsList, setGoodsList] = useState<any>([])
  const [goodsListSpinning, setGoodsListSpinning] = useState<boolean>(true)
  const { countUp, update } = useCountUp({
    start: 0,
    end: 0,
    delay: 2,
    duration: 5,
    separator: ',',
  })

  /* Renders block */
  const renderSortState = (stateNum: number) => {
    let ret = ''
    if (stateNum === 1) {
      ret = 'onSelect up'
    } else if (stateNum === 2) {
      ret = 'onSelect down'
    } else {
      ret = ''
    }
    return ret
  }

  /* render auchor Info Block */
  const RenderAuchorInfo = (props: any) => {
    const auchorInfo = props.data
    return (
      <div className="anchorDetail">
        <p className="name">{auchorInfo.nickName}</p>
        <p className="fans">
          {transformShortNum(auchorInfo.fansNum, 2, true, true)}粉丝
        </p>
        <p className="liveTime">开播时间：{auchorInfo.startTime}</p>
        <p className="liveLong">直播时长：{auchorInfo.livedTime}</p>
        <div className="timeConterBox">
          <CountdownTime
            tips="{time}秒后更新数据"
            time={20}
            cbFunc={refreshData}
            clearTimer={!isLiveing}
            endText="主播下播,数据停更"
          />
        </div>
      </div>
    )
  }

  /* render data card */
  const renderDataCard = (cardData: IAuchorInfo) => {
    return (
      <>
        <div className="numCard fullCard">
          <p>成交额(退款后)</p>
          <p className="num">
            ¥<em>{`${countUp as string}`}</em>
          </p>
        </div>
        {/* <div className="numCard halfCard">
          <p>销量</p>
          <p className="num">
            <em>{FormatNumbertoRMB(cardData.goodsNum, 0)}</em>件
          </p>
        </div>
        <div className="numCard halfCard mgrZero">
          <p>在线人数</p>
          <p className="num">
            <em>{FormatNumbertoRMB(cardData.audiencesOnline, 0)}</em>
          </p>
        </div> */}
        <ul className="listNum">
          <li>
            <p className="num">
              {transformShortNum(
                cardData.audiencesOnline,
                2,
                true,
                true,
                units
              )}
            </p>
            <p className="note">实时在线人数</p>
          </li>
          <li>
            <p className="num">
              {transformShortNum(
                auchorInfo.maxAudiencesOnline,
                2,
                true,
                true,
                units
              )}
            </p>
            <p className="note">最高在线人数</p>
          </li>
          <li>
            <p className="num">
              {transformShortNum(
                auchorInfo.totalFansIncrease,
                2,
                true,
                true,
                units
              )}
            </p>
            <p className="note">涨粉量</p>
          </li>
          <li>
            <p className="num">
              <em>¥</em>
              {transformShortNum(
                Number(auchorInfo.perOrderPriceAvg) / 100,
                2,
                true
              )}
            </p>
            <p className="note">笔单价</p>
          </li>
          <li>
            <p className="num">
              {transformShortNum(auchorInfo.orderNum, 2, true, true, units)}
            </p>
            <p className="note">成交订单量</p>
          </li>
          <li>
            <p className="num">
              {transformShortNum(cardData.goodsNum, 2, true, true, units)}
            </p>
            <p className="note">成交商品量</p>
          </li>
        </ul>
      </>
    )
  }

  const goodsDomRef = React.useRef<any>()
  const sortRef = React.useRef({
    isSort: false,
    isLoading: false,
  })
  useEffect(() => {
    if (sortRef.current.isSort && sortRef.current.isLoading) {
      const titleHeight = goodsDomRef.current.children[0].clientHeight
      const sortTop = goodsDomRef.current.getElementsByClassName('sortField')[0]
        .offsetTop
      document.documentElement.scrollTop =
        goodsDomRef.current.offsetTop * 2 - sortTop + titleHeight
      sortRef.current.isSort = false
      sortRef.current.isLoading = false
    }
  }, [goodsList])

  /* Actions */
  /* change Sort */
  const changeSortSate = (type: string) => {
    switch (type) {
      case 'sortOnline':
        const oSortOnline = sortOnline + 1
        const _sortOnlineNum = oSortOnline <= 2 ? oSortOnline : 0
        const _sortOnlineValue =
          _sortOnlineNum !== 0 ? (_sortOnlineNum === 1 ? 'ASC' : 'DESC') : ''
        setSortOnline(_sortOnlineNum)
        goodsListSortParams = {
          column: 'createTime',
          sort: _sortOnlineValue,
        }
        setSortSaleNum(0)
        setSortGoodsPrice(0)
        setSortGoodsGmv(0)
        break

      case 'sortSaleNum':
        let osortSaleNum = sortSaleNum + 1
        const _sortSaleNum = osortSaleNum <= 2 ? osortSaleNum : 0
        const _sortSaleValue =
          osortSaleNum <= 2 ? (osortSaleNum === 1 ? 'ASC' : 'DESC') : ''
        setSortSaleNum(_sortSaleNum)
        goodsListSortParams = {
          column: 'goodsNum',
          sort: _sortSaleValue,
        }
        setSortOnline(0)
        setSortGoodsPrice(0)
        setSortGoodsGmv(0)
        break

      case 'sortGoodsPrice':
        let osortGoodsPrice = sortGoodsPrice + 1
        const _sortGoodsPrice = osortGoodsPrice <= 2 ? osortGoodsPrice : 0
        const _sortGoodsPriceValue =
          osortGoodsPrice <= 2 ? (osortGoodsPrice === 1 ? 'ASC' : 'DESC') : ''
        setSortGoodsPrice(_sortGoodsPrice)
        goodsListSortParams = {
          column: 'goodsPrice',
          sort: _sortGoodsPriceValue,
        }
        setSortOnline(0)
        setSortSaleNum(0)
        setSortGoodsGmv(0)
        break

      case 'sortGoodsGmv':
        let osortGoodsGmv = sortGoodsGmv + 1
        const _sortGoodsGmv = osortGoodsGmv <= 2 ? osortGoodsGmv : 0
        const _sortGoodsGmvValue =
          osortGoodsGmv <= 2 ? (osortGoodsGmv === 1 ? 'ASC' : 'DESC') : ''
        setSortGoodsGmv(_sortGoodsGmv)
        goodsListSortParams = {
          column: 'orderAmount',
          sort: _sortGoodsGmvValue,
        }
        setSortOnline(0)
        setSortSaleNum(0)
        setSortGoodsPrice(0)
        break

      default:
        break
    }
    // setGoodsListSpinning(true)
    sortRef.current.isSort = true
    goodsListSort(goodsList)
    // setGoodsList([])
  }

  /* Get Data */
  /* Get Auchor Info */
  const getAuchorInfo = async () => {
    const res = await API.ApiLiveRealtimeGetLiveInfo18812({
      userNo: userNo,
      startTime: startTime,
    })

    if (res && res.data) {
      setIsLiveing(res.data.liveStatus === 1 ? true : false)
      if (res.data.liveStatus === 1) {
        setAuchorInfo({ ...auchorInfo, ...res.data })
      }
    }
  }

  // Data Sort
  const goodsListSort = (sourceList: any[]): void => {
    let retList = []
    let sort = 'a-b'
    if (goodsListSortParams.sort === 'ASC') {
      sort = 'a-b'
    } else if (goodsListSortParams.sort === 'DESC') {
      sort = 'b-a'
    } else {
      sort = ''
    }
    if (
      goodsListSortParams.column !== 'createTime' &&
      goodsListSortParams.sort !== ''
    ) {
      retList = objectSort(sourceList, goodsListSortParams.column, sort)
    } else {
      // 创建时间排序需要先处理为时间戳
      for (let i = 0; i < sourceList.length; i++) {
        sourceList[i].timestamp = new Date(
          sourceList[i].createTime.replace(/-/g, '/')
        ).valueOf()
      }
      retList = objectSort(sourceList, 'timestamp', sort === '' ? 'b-a' : sort)
    }
    setTimeout(() => {
      setGoodsList(retList)
      if (sortRef.current.isSort) {
        sortRef.current.isLoading = true
      }
    })
  }

  /* Get Goods List Data */
  const getGoodsListData = async () => {
    const res = await API.ApiLiveRealtimeGetGoodsDetail18813(sortParams)

    if (res && res.data && res.data.records) {
      setGoodsListSpinning(false)
      if (res.data.records.length) {
        goodsListSort(res.data.records)
        // setGoodsList(res.data.records)
      }
    }
  }
  /* refresh page Datas */
  const refreshData = () => {
    getAuchorInfo()
    getGoodsListData()
  }

  useEffect(() => {
    getAuchorInfo()
    getGoodsListData()
    // 返回顶部
    window.onscroll = (e: any) => {
      const floatBall = document.getElementById('J_floatBall')
      if (document.documentElement.scrollTop > 200) {
        floatBall.style.display = 'block'
      } else {
        floatBall.style.display = 'none'
      }
    }
  }, [])

  useEffect(() => {
    update(auchorInfo.orderAmount || 0)
  }, [auchorInfo])
  return (
    <div className="dataVpage">
      <div className="container">
        <div className="anchorInfo">
          <div className={`auchorHeadBox ${isLiveing ? '' : 'offline'}`}>
            {auchorInfo.headImg && auchorInfo.headImg !== 'null' ? (
              <img
                className="auchorHeadImg headImg"
                src={`${auchorInfo.headImg}${
                  auchorInfo.headImg.indexOf('Signature') !== -1
                    ? ''
                    : '?x-oss-process=image/resize,w_200,limit_0/quality,q_60'
                }`}
                alt={auchorInfo.nickName}
              />
            ) : (
              <div className="auchorHeadImg">
                <UserOutlined />
              </div>
            )}
            <div className="playState">
              {isLiveing ? (
                <>
                  <AlignLeftOutlined
                    style={{
                      transform: 'rotate(270deg)',
                      marginRight: '2px',
                    }}
                  />
                  直播中
                </>
              ) : (
                '已下播'
              )}
            </div>
          </div>
          <RenderAuchorInfo data={auchorInfo} />
        </div>
        {renderDataCard(auchorInfo)}
        {/* Goods List */}
        <div className="goodsListTitle" ref={goodsDomRef}>
          <p>
            商品详情
            <span className="goodsTotalNum fr">共{goodsList.length}件</span>
          </p>
          <div className="goodsFilter" key={`${JSON.stringify(sortParams)}`}>
            <div
              className={`goodsFilterItem ${renderSortState(sortOnline)}`}
              onClick={() => {
                changeSortSate('sortOnline')
              }}
            >
              <span className="sortField">上架时间</span>
            </div>
            <div
              className={`goodsFilterItem ${renderSortState(sortGoodsPrice)}`}
              onClick={() => {
                changeSortSate('sortGoodsPrice')
              }}
            >
              <span className="sortField">直播价</span>
            </div>
            <div
              className={`goodsFilterItem ${renderSortState(sortSaleNum)}`}
              onClick={() => {
                changeSortSate('sortSaleNum')
              }}
            >
              <span className="sortField">成交商品量</span>
            </div>
            <div
              className={`goodsFilterItem ${renderSortState(sortGoodsGmv)}`}
              onClick={() => {
                changeSortSate('sortGoodsGmv')
              }}
            >
              <span className="sortField">成交额</span>
            </div>
          </div>
        </div>
        <>
          <div className="goodsListBox">
            <Spin key="goodsSpin" size="large" spinning={goodsListSpinning}>
              {goodsList && goodsList.length
                ? goodsList.map((item, index) => {
                    let sortNum = index + 1
                    return (
                      <div className="goodsItem" key={item.goodsId}>
                        <div className="imgBox">
                          <img
                            className="goodsImg"
                            src={
                              item.goodsImg
                                ? `${item.goodsImg}?x-oss-process=image/resize,w_200,limit_0/quality,q_60`
                                : require('../../../assets/baseGoods.png')
                            }
                            alt={item.goodsName}
                          />
                          <i className={`sortNum sortNum_${sortNum}`}>
                            {sortNum}
                          </i>
                        </div>
                        <div className="detailInfo">
                          <p className="title">{item.goodsName}</p>
                          <ul>
                            <li>
                              <p className="note">直播价</p>
                              <p className="num">
                                <em>¥</em>
                                {item.goodsPrice === 0
                                  ? 0
                                  : item.goodsPrice !== null
                                  ? transformShortNum(item.goodsPrice, 2, true)
                                  : '-'}
                              </p>
                            </li>
                            <li>
                              <p className="note">成交商品量</p>
                              <p className="num">
                                {item.goodsNum === 0
                                  ? '0'
                                  : item.goodsNum !== null
                                  ? transformShortNum(
                                      item.goodsNum,
                                      2,
                                      true,
                                      true,
                                      ['W', 'E']
                                    )
                                  : '-'}
                              </p>
                            </li>
                            <li>
                              <p className="note">成交额</p>
                              <p className="num">
                                <em>¥</em>
                                {item.orderAmount === 0
                                  ? 0
                                  : item.orderAmount !== null
                                  ? transformShortNum(
                                      item.orderAmount,
                                      2,
                                      true,
                                      false,
                                      ['W', 'E']
                                    )
                                  : '-'}
                              </p>
                            </li>
                          </ul>
                        </div>
                        <p className="onlineTime">
                          上架时间：
                          {item.createTime
                            ? moment(item.createTime).format('HH:mm:ss')
                            : '-'}
                        </p>
                      </div>
                    )
                  })
                : !goodsListSpinning && (
                    <EmptyStatus
                      type={1}
                      text="暂无商品"
                      style={{
                        margin: '2.6rem auto 0',
                        textAlign: 'center',
                        lineHeight: '1.6',
                      }}
                    />
                  )}
            </Spin>
          </div>
          <a className="floatBall" id="J_floatBall" href="#">
            <ArrowUpOutlined />
          </a>
        </>
      </div>
    </div>
  )
}
