import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import type { ITeamInfo } from './interface'
import { GetQueryString, transformShortNum } from '../../../utils/index'
import './style/index.less'
import EmptyStatus from '../../../components/EmptyStatus'
import API from '../../../api/index'

// 队ID定义
const TEAM_IDS = {
  '红队':{
    name: '红队',
    id:'T0001',
    className:'',
  },
  '蓝队':{
    name: '蓝队',
    id:'T0002',
    className:'blue',
  },
  '紫队':{
    name: '紫队',
    id:'T0003',
    className:'purple',
  },
}
// 默认公司数据
const defaultSummaryData = {
  "targetGmv": '-',
  "payGmvRate": '-',
  "timeProcess": '-',
  "liveDuration": '-',
  "payGoodsCount": '-',
  "payGmv": '-',
  "dealGoodsCount": '-',
  "dealGmv": '-'
}
// 默认队列表
const defaultTeamList = []

export default () => {
  const [summaryData,setSummaryData] = useState<any>(defaultSummaryData)
  const [teamInfoList,setTeamInfoList] = useState<ITeamInfo[]>(defaultTeamList)
  const [summaryDataLoading,setSummaryDataLoading] = useState<boolean>(false)
  const [teamInfoListLoading,setTeamInfoListLoading] = useState<boolean>(false)
  /**
  const goodsDomRef = React.useRef<any>()
  const sortRef = React.useRef({
    isSort: false,
    isLoading: false,
  })
  */
  /* Actions */
  const openTeamData = (teamId: any) => {
    window.location.href = `${window.location.origin}/activity618Team?tid=${teamId}`
  }

  /* Get Data */
  // 获取全局汇总数据
  const getSummaryData = async () =>{
    setSummaryDataLoading(true)
    const res:any = await API.ApiGrandCompany29519()
    if (res && res.data) {
      setSummaryData(res.data)
      setSummaryDataLoading(false)
    }
  }
  // 获取战队数据
  const getTeamData = async() => {
    setTeamInfoListLoading(true)
    const res:any = await API.ApiGrandTeam29512()
    if (res && res.data) {
      setTeamInfoList(res.data)
      setTeamInfoListLoading(false)
    }
  }

  /* loaded */
  useEffect(() => {
    // Get Data
    getSummaryData()
    getTeamData()
  }, [])

  return (
    <div className="activity618page">
      <div className="container">
        {/* 头部 */}
        <div className="header">
          <div className="taskobjBox">
            <p>
              17.705<em>亿</em>
            </p>
            <p className="note">总目标付款GMV</p>
          </div>
          <div className="sloganbox"></div>
        </div>
        {/* 总进度 */}
        <div className="masterProgramme">
          <div className="left">
            <div className="ainconbox">
              <img
                className="aicon"
                src="https://cdn.ywwl.com/bps/dianhu-m/2021618/icon01@2x.png"
              />
            </div>
            <div className="numArea">
              <p>
                <Spin size="small" spinning={summaryDataLoading}>{transformShortNum(summaryData.payGmv,2,false)}</Spin><em>亿</em>
              </p>
              <p className="note">总付款GMV</p>
            </div>
          </div>
          <div className="right">
            <div className="ainconbox">
              <img
                className="aicon"
                src="https://cdn.ywwl.com/bps/dianhu-m/2021618/icon02@2x.png"
              />
            </div>
            <div className="numArea">
              <p>
                <Spin size="small" spinning={summaryDataLoading}>{`${transformShortNum(summaryData.payGmvRate*100,0,false)}`}</Spin><em>%</em>
              </p>
              <p className="note">GMV完成率</p>
            </div>
          </div>
        </div>
        {/* 各队汇总 */}
        <div className="summaryData">
          <div className="title">
            <img
              src="https://cdn.ywwl.com/bps/dianhu-m/2021618/cbtn-01.png"
              alt=""
            />
            <h2>战队完成率</h2>
            <span className="r">时间进度：<Spin size="small" spinning={summaryDataLoading}>{`${transformShortNum(summaryData.timeProcess*100,0,false,true)}`}</Spin>%</span>
          </div>
          {/* 战队Item */}
          <Spin spinning={teamInfoListLoading}>
            {teamInfoList && teamInfoList.length > 0 ? teamInfoList.map((item: any,index:number) =>(
              <div className="teamItem"
                key={`TItem_${index}`}
                onClick={() => openTeamData(TEAM_IDS[item.groupName].id)}
              >
              <div className="progressArea">
                <div className={`progressImg ${TEAM_IDS[item.groupName].className}`}>
                  {transformShortNum(item.payGmvRate*100,0,false,true)}<em>%</em>
                </div>
                <div className="teamInfo">
                  <p className="namebar">
                    <span className="name">{item.groupName}</span>
                    <span className="teamLeader">{item.leader}</span>
                  </p>
                  <div className="progressBar">
                    <span className="fl">{transformShortNum(item.payGmv,2)}</span>
                    <span className="fr">{transformShortNum(item.targetGmv,2)}</span>
                    <div className="onBar" style={{ width: `${Number(transformShortNum(item.payGmvRate*100,0,false,true)) <=100 ? transformShortNum(item.payGmvRate*100,0,false,true) : 100}%` }}></div>
                  </div>
                </div>
              </div>
              <div className="dataArea">
                <div className="item">
                  <p className="name">直播时长</p>
                  <p className="num">{item.liveDuration}</p>
                </div>
                <div className="item">
                  <p className="name">付款商品量</p>
                  <p className="num">{transformShortNum(item.payGoodsCount,2)}</p>
                </div>
                <div className="item">
                  <p className="name">成交商品量</p>
                  <p className="num">{transformShortNum(item.dealGoodsCount,2)}</p>
                </div>
                <div className="item">
                  <p className="name">成交GMV</p>
                  <p className="num">{transformShortNum(item.dealGmv,2)}</p>
                </div>
              </div>
            </div>
            )):!teamInfoListLoading && (
              <EmptyStatus
                type={1}
                text="暂无数据"
                style={{
                  margin: '2.6rem auto 0',
                  textAlign: 'center',
                  lineHeight: '1.6',
                }}
              />
            )}
          </Spin>
          {/* 战队Item */}
          {/* <div className="teamItem">
            <div className="progressArea">
              <div className="progressImg">
                63<em>%</em>
              </div>
              <div className="teamInfo">
                <p className="namebar">
                  <span className="name">红队</span>
                  <span className="teamLeader">逍遥子、克里斯张</span>
                </p>
                <div className="progressBar">
                  <span className="fl">8416.2W</span>
                  <span className="fr">20050.0W</span>
                  <div className="onBar" style={{ width: '63%' }}></div>
                </div>
              </div>
            </div>
            <div className="dataArea">
              <div className="item">
                <p className="name">直播时长</p>
                <p className="num">96.12H</p>
              </div>
              <div className="item">
                <p className="name">付款商品量</p>
                <p className="num">68.62W</p>
              </div>
              <div className="item">
                <p className="name">成交商品量</p>
                <p className="num">54.2W</p>
              </div>
              <div className="item">
                <p className="name">成交GMV</p>
                <p className="num">854.2W</p>
              </div>
            </div>
          </div> */}
          {/* 战队Item end*/}
        </div>
      </div>
    </div>
  )
}
