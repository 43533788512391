/* 快速排序 */
export const quickSort = (left, right, arr): any => {
  // 递归终止条件
  if (left > right) return;
  const temp = arr[left]; // 设置基准数
  // 左右开弓 查找，要求左小右大，不符合规律的调整位置
  let i = left;
  let j = right;
  while (i < j) {
    while (i < j && arr[j] >= temp) {
      j--;
    }
    while (i < j && arr[i] <= temp) {
      i++;
    }
    // 不符规律的 i与j对应的值互换位置
    // eslint-disable-next-line one-var
    const a = arr[i],
      b = arr[j];
    arr[i] = b;
    arr[j] = a;
  }
  // 循环到 i j 碰头即是 i === j, 基准值放进i，原来基准值位置由对应值填充
  arr[left] = arr[i];
  arr[i] = temp;
  // 两头开始 递归
  quickSort(left, i - 1, arr);
  quickSort(j + 1, right, arr);
};

/**
 * 对象数组按指定字段排序
 * @param sortObject 需要排序的对象数据
 * @param sortField 排序的字段
 * @param sortedBy 排序方法.'a-b' 从小到大. 'b-a'从大到小
 */
export const objectSort = (sortObject: any[], sortField: string, sortedBy: string): any => {
  let t = []
  let keys = []
  let ret = []

  sortObject.map((item) => {
    t.push(item)
    keys.push(item[sortField])
  })

  // 默认'a-b'
  switch (sortedBy) {
    case 'b-a':
      keys.sort((a, b) => {
        return b - a
      })
      break;

    default:
      keys.sort((a, b) => {
        return a - b
      })
      break;
  }

  for (let i = 0; i < keys.length; i++) {
    for (let j = 0; j < t.length; j++) {
      if (keys[i] === t[j][sortField]) {
        ret.push(t[j])
        t.splice(j, 1)
        break
      }
    }
  }

  return ret
}
