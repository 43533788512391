
    /**
    * 618战报 公司维度
    * 
    **/
    
    // @ts-nocheck
    import request from '../utils/http'
    type Serve<T, G> = (data?: T) => Promise<G>
    export class IReqApiGrandCompany29519 {}
    /**
 * YResult<CompanyGrandVo> :YResult
 */
export class IResApiGrandCompany29519 {
  code?: {};
  msg?: {};
  success?: {};
  /**
   * CompanyGrandVo
   */
  data?: {
    /**
     * 目标gmv
     */
    targetGmv?: {};
    /**
     * 支付gmv比例
     */
    payGmvRate?: {};
    /**
     * 时间进度
     */
    timeProcess?: {};
    /**
     * 直播时长
     */
    liveDuration?: {};
    /**
     * 支付商品数量
     */
    payGoodsCount?: {};
    /**
     * 支付gmv
     */
    payGmv?: {};
    /**
     * 成交商品数量
     */
    dealGoodsCount?: {};
    /**
     * 成交gmv
     */
    dealGmv?: {};
  };
  exceptionMsg?: {};
}
    const http: Serve<IReqApiGrandCompany29519, IResApiGrandCompany29519> = (data?) =>  request({
      method: 'GET',
      url: '/api/grand/company',
      params: data
    }) 
    export default http