
    /**
    * 正在直播列表
    * 
    **/
    
    // @ts-nocheck
    import request from '../utils/http'
    type Serve<T, G> = (data?: T) => Promise<G>
    export class IReqApiLiveRealtimeGetLiveList18811 {}
    export class IResApiLiveRealtimeGetLiveList18811 {
  code?: number;
  data?: {
    /**
     * 在线人数
     */
    fansNum: number;
    /**
     * 主播名称
     */
    nickName: string;
    /**
     * 主播id
     */
    userNo: string;
    /**
     * 开播时间
     */
    startTime: string;
    /**
     * 主播封面图片url
     */
    liveImg: string;
    /**
     * 平台类型  快手，抖音
     */
    liveType: '快手' | '抖音';
    /**
     * 总销售额（包含退款的）
     */
    orderAmount: number;
    /**
     * 实际gmv（不包含退款的）
     */
    actGmv: string;
    /**
     * 在线人数
     */
    audiencesOnline: number;
    /**
     * 总销售数
     */
    goodsNum: number;
  }[];
  msg?: string;
  success?: boolean;
}
    const http: Serve<IReqApiLiveRealtimeGetLiveList18811, IResApiLiveRealtimeGetLiveList18811> = (data?) =>  request({
      method: 'GET',
      url: '/api/live/realtime/getLiveList',
      params: data
    }) 
    export default http