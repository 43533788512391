import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import qs from 'query-string'
import * as dd from 'dingtalk-jsapi'
import { Context } from './context'
import LiveList from './page/livelist/Index'
import DataV from './page/datav/Index'
import Activity618 from './page/activity618/Index'
import Activity618Team from './page/activity618Team/Index'
import Demo from './page/demo/Index'
import './../node_modules/antd/dist/antd.css'

const App = () => {
  /**处理地址栏token**/
  const { query, url } = qs.parseUrl(window.location.href)
  const isDingTalk = window.navigator.userAgent.includes('DingTalk')
  const isAndroid = /Android/i.test(navigator.userAgent)

  if (query && query['token']) {
    const { token, loginAuto, ...queryOther } = query
    const queryStr = qs.stringify(queryOther)
    localStorage.setItem('token', token as any)

    const goUrl = `${url}${queryStr ? '?' + queryStr : ''}`

    // window.location.replace(goUrl)
  }

  // 去掉钉钉容器右上角菜单
  /**
  if (isDingTalk) {
    dd.ready(() => {
      dd.biz.navigation.setRight({
        show: true, // 控制按钮显示， true 显示， false 隐藏， 默认true
        control: true, // 是否控制点击事件，true 控制，false 不控制， 默认false
        text: '.', // 控制显示文本，空字符串表示显示默认文本
        onSuccess: () => {
          //如果control为true，则onSuccess将在发生按钮点击事件被回调
          return false
        },
      })
    })
  }
  **/

  const platform = /Android|webOS|iPhone|iPad|BlackBerry/i.test(
    navigator.userAgent
  )
    ? 'mobile'
    : 'pc'

  return (
    <Context.Provider value={platform}>
      <Router>
        <Switch>
          <Route exact path="/" component={LiveList} />
          <Route exact path="/datav" component={DataV} />
          <Route exact path="/Activity618" component={Activity618} />
          <Route exact path="/Activity618Team" component={Activity618Team} />
          <Route exact path="/demo" component={Demo} />
          <Redirect push to="/" />
        </Switch>
      </Router>
    </Context.Provider>
  )
}

export default App
