export default {
  development: {
    // api: "http://yapi.ywfe.com/mock/193",
    api: "https://dev-gateway.ywwl.com/ywwl-dianhu-oper",
    loginHost: 'http://dsjh5.fed.ywwl.com',
    static: '/',
  },
  debug: {
    api: "https://dev-gateway.ywwl.com/ywwl-dianhu-oper",  // 联调
    loginHost: 'http://dsjh5.fed.ywwl.com',
    static: "/"
  },
  test: {
    api: "https://test-gateway.ywwl.com/ywwl-dianhu-oper",
    loginHost: 'https://mdw-test.ywwl.com',
    static: "/"
  },
  production: {
    api: 'https://gateway.ywwl.com/ywwl-dianhu-oper',
    loginHost: 'https://mdw.ywwl.com',
    static: '/',
  },
}
