import React from 'react';
import styled from 'styled-components';

const Toast = ({msg}:{msg:string}) => {
  return <Container>{msg}</Container>
}

const Container = styled.div`
  max-width: 80vw;
  background: rgb(0,0,0,0.6);
  border-radius: 5px;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 1.6;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.122rem 0.123rem;
`
export default Toast;
