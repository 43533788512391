import React from 'react'
import { Empty } from 'antd'

interface IProps {
  text?: string
  type?: number // 1：列表为空样式, 2: 表格为空样式
  style?: {}
  className?: string
}

export default (props: IProps) => {
  const defaultProps = {
    text: '当前暂无数据',
    type: 1,
    style: {},
  }
  const imageConfig =
    (props.type || defaultProps.type) === 1
      ? {
          path: 'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg',
          style: { height:'2.52rem', margin: '0 auto', display: 'block',},
        }
      : {
          path: require('../../../src/assets/table_empty.png'),
          style: { height:'1.72rem', margin: '0 auto', display: 'block',},
        }
  return (
    <Empty
      style={{
        color: '#2e384d',
        ...{ ...defaultProps.style, ...(props.style || {}) },
      }}
      className={props.className || ''}
      image={imageConfig.path}
      imageStyle={imageConfig.style}
      description={props.text || defaultProps.text}
    />
  )
}
