import React from 'react'
import { Context } from '../../context'
// import Pc from './pc/Index'
import Mobile from './mobile/Index'

export default function() {
  return (
    <Context.Consumer>
      {(value) => (value === 'pc' ? <Mobile /> : <Mobile />)}
    </Context.Consumer>
  )
}
